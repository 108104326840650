.optionsLegend_rowDiv {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.optionsLegend_optionTextDiv {
  margin-left: 10px;
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 16px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
