.questionList_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionList_underline {
  height: 1px;
  width: 50%;
  background-image: linear-gradient(to right, #0F63A4, #B0D2EC);
  margin-bottom: 10px;
}

.questionList_emptyListNoteDiv {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.questionList_ul {
  margin: 0;
  padding: 0;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
