.answerQuestionPage_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 20px;
}

.answerQuestionPage_underline {
  height: 1px;
  width: 50%;
  background-image: linear-gradient(to right, #0f63a4, #b0d2ec);
  margin-bottom: 20px;
}

.answerQuestionPage_ul {
  margin: 0;
  padding: 0;
}

.answerQuestionPage_submitDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  height: 40px;
}

.answerQuestionPage_submit {
  height: 40px;
  width: 90px;
  background-color: #0f63a4;
  color: white;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.answerQuestionPage_submit:hover {
  height: 40px;
  width: 90px;
  background-color: #548fbd;
  color: white;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  border: 0;
}

.answerQuestionPage_turnstileContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 30px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
