.questionItem_li {
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none;
}

.questionItem_div {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  color: black;
  box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.15);
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.questionItem_div:hover {
  background-color: rgb(238, 238, 238);
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  color: black;
  box-shadow: 1px 2px 7px 3px rgba(0, 0, 0, 0.25);
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  transition: 0.2s;
}

.questionItem_link {
  text-decoration: none;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
