.filterSort_container1 {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.filterSort_container2 {
  margin-right: 20px;
}

.filterSort_selectLabel {
  font-family: "BarlowRegular";
  font-size: 18px;
  margin-bottom: 8px;
}

.filterSort_select {
  height: 36px;
  width: 160px;
  background-color: #ffe8a4;
  color: black;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  padding-left: 5px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
