.newUserPage_titleContainer {
  display: flex;
  flex-direction: row;
  background-color: #454545;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.35);
}

.newUserPage_titleNameDiv {
  display: flex;
  width: 65%;
  justify-content: left;
  align-items: center;
}

.newUserPage_titleNameImg {
  height: 40px;
}

.newUserPage_titleLogoDiv {
  display: flex;
  width: 35%;
  justify-content: right;
  align-items: center;
}

.newUserPage_titleLogoImg {
  height: 40px;
}

.newUserPage_mainContainer {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.newUserPage_h1 {
  font-family: "ShareBold", "Tahoma", sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  margin: 0px;
  margin-bottom: 12px;
}

.newUserPage_formDiv {
  margin-bottom: 50px;
}

.newUserPage_collapseHeadingDiv {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
}

.newUserPage_collapseHeadingDiv:hover {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: rgb(220, 220, 220);
}

.newUserPage_collapseChevronDiv {
  width: 15px;
  margin-right: 10px;
}

.newUserPage_collapseChevronImgCollapsed {
  width: 100%;
  height: auto;
  transition: 0.15s;
}

.newUserPage_collapseChevronImgExpanded {
  width: 100%;
  height: auto;
  transition: 0.15s;
  transform: rotate(90deg);
}

.newUserPage_collapseHeading {
  font-family: "ShareRegular";
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  margin: 0px;
}

.newUserPage_collapseSectionContainer {
  border-top: solid black 1px;
}

.newUserPage_divCollapsed {
  display: none;
  height: 0%;
  transition: 2s;
}

.newUserPage_divExpanded {
  display: block;
  height: 100%;
  padding-bottom: 30px;
  transition: 2s;
}

.newUserPage_bottomBufferContainer {
  margin-bottom: 60px;
  border-top: solid black 1px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
