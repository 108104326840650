.filterResultsForm_h2 {
  font-family: "ShareRegular";
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  margin: 0px;
}

.filterResultsForm_containerDiv {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.filterResultsForm_checkboxesDiv {
  margin-right: 50px;
  margin-bottom: 20px;
}

.filterResultsForm_checkboxesHeader {
  font-family: "BarlowItalic";
  font-size: 18px;
  margin-bottom: 5px;
}

.filterResultsForm_checkboxesContainer_decade {
  border: 1px solid rgb(210, 210, 210);
  width: 120px;
  height: 150px;
  overflow-y: scroll;
}

.filterResultsForm_checkboxesContainer_location {
  border: 1px solid rgb(210, 210, 210);
  width: 200px;
  height: 150px;
  overflow-y: scroll;
}

.filterResultsForm_checkboxInput {
  accent-color: #ffc107;
}

.filterResultsForm_toggleAllButton {
  background-color: #ffe8a4;
  border-width: 1px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
