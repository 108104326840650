.aboutPolldock_textBlock {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.aboutPolldock_bottomPadding {
  padding-top: 100px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
