.termsAndConditions_h2 {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.termsAndConditions_h3 {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  font-size: 26px;
  color: #0f63a4;
  margin-top: 30px;
  margin-bottom: 24px;
}

.termsAndConditions_h4 {
  font-family: "BarlowBoldItalic", "Tahoma", sans-serif;
  font-size: 22px;
  color: #0f63a4;
  margin-top: 30px;
  margin-bottom: 18px;
}

.termsAndConditions_textBlock {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.asdf {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.termsAndConditions_ul1 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.termsAndConditions_li1 {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 16px;
}

.termsAndConditions_li1 > strong {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.termsAndConditions_li2 {
  list-style: none;
  margin-top: 36px;
  margin-bottom: 36px;
}

.termsAndConditions_textLineDiv {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  font-size: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.termsAndConditions_textLineDiv > strong {
  font-family: "BarlowBold", "Tahoma", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.termsAndConditions_link {
  font-family: "BarlowRegular", "Tahoma", sans-serif;
  color: #0f63a4;
  text-decoration: none;
}

.termsAndConditions_bottomPadding {
  padding-top: 100px;
}

/* Imported fonts */
@font-face {
  font-family: "BarlowRegular";
  src: url("../fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowItalic";
  src: url("../fonts/Barlow/Barlow-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBold";
  src: url("../fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BarlowBoldItalic";
  src: url("../fonts/Barlow/Barlow-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ShareRegular";
  src: url("../fonts/Share/Share-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ShareBold";
  src: url("../fonts/Share/Share-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OffsideRegular";
  src: url("../fonts/Offside/Offside-Regular.ttf") format("truetype");
}
